import { RestartAlt } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText } from '@mui/material';

type HeaderProps = {
  onResetValues: () => void;
};
const Header = ({ onResetValues }: HeaderProps) => {
  return (
    <ListItem
      disableGutters
      // secondaryAction={
      //   <IconButton onClick={onResetValues}>
      //     <RestartAlt />
      //   </IconButton>
      // }
    >
      <ListItemText primary="Commission Splits" secondary="Advisor sourced" />
    </ListItem>
  );
};

export default Header;
