import { LinearProgress, type LinearProgressProps, Stack } from '@mui/material';
import T from 'components/common/T';

type CommissionSplitsLinearProgressProps = Omit<
  LinearProgressProps,
  'color' | 'variant'
> & {
  currentTotalTakePct: number | null;
  maxPercent: number;
};
const CommissionSplitsLinearProgress = ({
  currentTotalTakePct,
  maxPercent,
  ...props
}: CommissionSplitsLinearProgressProps) => {
  const color = currentTotalTakePct === maxPercent ? 'success' : 'error';
  const linearProgressValue =
    currentTotalTakePct === null
      ? undefined
      : (currentTotalTakePct / maxPercent) * 100 > 100
        ? 100
        : (currentTotalTakePct / maxPercent) * 100;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <LinearProgress
        variant="determinate"
        color={color}
        value={linearProgressValue}
        {...props}
        sx={{ flex: 1 }}
      />
      <T
        variant="body2"
        color="text.secondary"
      >{`Total ${currentTotalTakePct}% / ${maxPercent}%`}</T>
    </Stack>
  );
};

export default CommissionSplitsLinearProgress;
