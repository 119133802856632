import { roundToTwoDecimals } from 'utils/client/formatting';
import type { TripFormValues } from '../schema';
import { type EditedSplitTypes, TripSplitType } from './dtos';

export const tripSplitTypeNames: Record<TripSplitType, string> = {
  [TripSplitType.AIRLINE]: 'Airline',
  [TripSplitType.CRUISE_LINE]: 'Cruise Line',
  [TripSplitType.HOTEL]: 'Hotel',
  [TripSplitType.INSURANCE]: 'Insurance',
  [TripSplitType.RAIL]: 'Rail',
  [TripSplitType.TOUR_DMC]: 'Tour DMC',
  [TripSplitType.TRANSPORTATION]: 'Transportation',
  [TripSplitType.OTHER]: 'Other',
  [TripSplitType.DEFAULT]: 'All',
  [TripSplitType.ANCILLARIES]: 'Ancillaries',
};

export const defaultEditedSplitTypes: EditedSplitTypes = {
  [TripSplitType.DEFAULT]: { isOverride: false, hasError: false },
  [TripSplitType.AIRLINE]: { isOverride: false, hasError: false },
  [TripSplitType.HOTEL]: { isOverride: false, hasError: false },
  [TripSplitType.CRUISE_LINE]: { isOverride: false, hasError: false },
  [TripSplitType.INSURANCE]: { isOverride: false, hasError: false },
  [TripSplitType.OTHER]: { isOverride: false, hasError: false },
  [TripSplitType.RAIL]: { isOverride: false, hasError: false },
  [TripSplitType.TOUR_DMC]: { isOverride: false, hasError: false },
  [TripSplitType.TRANSPORTATION]: { isOverride: false, hasError: false },
  [TripSplitType.ANCILLARIES]: { isOverride: false, hasError: false },
};

export const calculateTotalTakePct = ({
  selectedSplitType,
  splits,
}: {
  splits: TripFormValues['splits'] | null;
  selectedSplitType: TripSplitType;
}) => {
  if (!splits) return 0;

  return roundToTwoDecimals(
    splits[selectedSplitType].splits.reduce(
      (acc, splitTypeTakes) => acc + splitTypeTakes.takePercent,
      0,
    ),
  );
};

export const calculateEditedSplitTypes = ({
  formSplits,
}: {
  formSplits: TripFormValues['splits'];
}) => {
  if (!formSplits) return defaultEditedSplitTypes;
  const editedSplitTypes = { ...defaultEditedSplitTypes };

  Object.keys(formSplits).forEach((splitType) => {
    const actualTotalTake = calculateTotalTakePct({
      selectedSplitType: splitType as TripSplitType,
      splits: formSplits,
    });

    const hasError =
      actualTotalTake !==
      roundToTwoDecimals(formSplits[splitType as TripSplitType].maxPercent);

    editedSplitTypes[splitType as TripSplitType].hasError = hasError;

    const currentTypeSplits = formSplits[splitType as TripSplitType].splits;
    editedSplitTypes[splitType as TripSplitType].isOverride =
      currentTypeSplits.some((split) => split.isOverride);
  });
  return editedSplitTypes;
};

export const getCommissionSplitsErrorsString = (
  editedSplitTypes: EditedSplitTypes,
) => {
  const errorTypes = Object.keys(editedSplitTypes).filter(
    (type) => editedSplitTypes[type as TripSplitType].hasError,
  );

  if (errorTypes.length === 0) return undefined;

  let errorString = errorTypes
    .map((type) => tripSplitTypeNames[type as TripSplitType])
    .join(', ');

  if (errorTypes.length > 1) {
    const lastIndex = errorString.lastIndexOf(',');
    errorString = `${errorString.substring(
      0,
      lastIndex,
    )} and${errorString.substring(lastIndex + 1)}`;
  }

  return `Max total percent on ${errorString} ${
    errorTypes.length > 1 ? 'are' : 'is'
  } incorrect`;
};
