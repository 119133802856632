import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useState } from 'react';

type CommissionSplitTakeDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (newTake: number) => void;
  commissionTake: number;
};

const CommissionSplitTakeDialog = ({
  open,
  onClose,
  onSave,
  commissionTake: initialValue,
}: CommissionSplitTakeDialogProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [commissionTake, setCommissionTake] = useState<number>(initialValue);

  const handleValueChange = (value: string | number) => {
    const percent = Number(value);

    isNaN(percent)
      ? setErrorMessage('Split percentage should be a valid number')
      : percent < 0 || percent > 100
        ? setErrorMessage('Split percentage should be between 0 and 100')
        : setErrorMessage(null);

    setCommissionTake(percent);

    return;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle>Edit commission split</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          name="groupName"
          label="Split"
          variant="standard"
          fullWidth
          value={commissionTake}
          onChange={(e) => handleValueChange(e.target.value)}
          autoComplete="off"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={() => handleValueChange(initialValue)}
                  disabled={initialValue === commissionTake}
                >
                  Reset
                </Button>
              </InputAdornment>
            ),
          }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSave(commissionTake)}
          variant="contained"
          disabled={!!errorMessage}
        >
          Update Split
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionSplitTakeDialog;
