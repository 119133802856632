import {
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from '@mui/material';
import BookingCommissionSplitsAdvisorAvatar from 'components/bookings/BookingDialog/tabs/splits/BookingCommissionSplitsAdvisorAvatar';
import BookingCommissionSplitsAgencyAvatar from 'components/bookings/BookingDialog/tabs/splits/BookingCommissionSplitsAgencyAvatar';
import T from 'components/common/T';
import { SplitType, generateColorForId } from 'dtos';
import { getInitials } from 'utils/client/contextTree';
import type { SplitTreeItem } from './dtos';

type CommissionSplitsTreeItemProps = {
  split: SplitTreeItem;
  onClick: (split: SplitTreeItem) => void;
  showPrimaryAdvisorChip?: boolean;
};

const CommissionSplitsTreeItem = ({
  onClick,
  showPrimaryAdvisorChip = false,
  split: splitTreeItem,
}: CommissionSplitsTreeItemProps) => {
  const { split, index } = splitTreeItem;

  return (
    <ListItemButton dense onClick={() => onClick(splitTreeItem)}>
      <Box pl={index * 3} />
      <ListItemIcon>
        {split.type === SplitType.AGENCY ? (
          <BookingCommissionSplitsAgencyAvatar
            color={split.color ?? generateColorForId(split.id || '')}
            initials={getInitials(split.name || '- -')}
          />
        ) : (
          <BookingCommissionSplitsAdvisorAvatar
            color={split.color || '#000'}
            initials={getInitials(split.name)}
          />
        )}
      </ListItemIcon>

      <ListItemText primary={split.name} />

      <ListItemSecondaryAction>
        <Stack direction="row" spacing={2} alignItems="center">
          {showPrimaryAdvisorChip && split.type === SplitType.ADVISOR && (
            <Chip size="small" variant="outlined" label="Primary advisor" />
          )}
          <T variant="body2">{split.takePercent}%</T>
        </Stack>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default CommissionSplitsTreeItem;
