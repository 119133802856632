import { Alert, AlertTitle } from '@mui/material';

type CommissionSplitsErrorSectionProps = {
  currentTotalTakePct: number;
  maxPercent: number;
};

const CommissionSplitsErrorSection = ({
  maxPercent,
  currentTotalTakePct,
}: CommissionSplitsErrorSectionProps) => {
  const errorMessage =
    currentTotalTakePct !== maxPercent
      ? currentTotalTakePct < maxPercent
        ? 'Total less than expected'
        : 'Total greater than expected'
      : null;

  return (
    errorMessage && (
      <Alert severity="error" sx={{ alignItems: 'center', mb: 2 }}>
        <AlertTitle sx={{ p: 0, m: 0 }}>{errorMessage}</AlertTitle>
      </Alert>
    )
  );
};

export default CommissionSplitsErrorSection;
