import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  type SxProps,
  alpha,
} from '@mui/material';
import {
  type EditedSplitTypes,
  TripSplitType,
} from 'components/trips/TripDialog/Splits/dtos';
import {
  getCommissionSplitsErrorsString,
  tripSplitTypeNames,
} from 'components/trips/TripDialog/Splits/utils';
import { SupplierType } from 'dtos';
import T from './T';

type SplitTypeSelectorProps = {
  selectedSplitType: TripSplitType;
  editedSplitTypes: EditedSplitTypes;
  setSelectedSplitType: (splitType: TripSplitType) => void;
  sx?: SxProps;
  isPerSupplierTypeSplit?: boolean;
};

const SplitTypeSelector = ({
  selectedSplitType,
  editedSplitTypes,
  setSelectedSplitType,
  sx,
  isPerSupplierTypeSplit,
}: SplitTypeSelectorProps) => {
  const hasAnyError = Object.values(editedSplitTypes).some(
    ({ hasError }) => hasError,
  );

  const { isOverride, hasError } = editedSplitTypes[selectedSplitType];

  return (
    <Box sx={sx}>
      <Select
        value={selectedSplitType}
        disabled={!isPerSupplierTypeSplit}
        fullWidth
        onChange={(e) => setSelectedSplitType(e.target.value as TripSplitType)}
        renderValue={(value) => {
          return (
            <T color={hasError ? 'error' : 'inherit'}>
              {tripSplitTypeNames[value]}
              {isOverride && (
                <T
                  component="span"
                  color={hasError ? 'error' : alpha('#000', 0.6)}
                >
                  {' '}
                  (edited)
                </T>
              )}
            </T>
          );
        }}
        error={hasAnyError}
      >
        {!isPerSupplierTypeSplit
          ? Object.values(TripSplitType).map((type) => (
              <MenuItem key={type} value={type}>
                <T
                  color={editedSplitTypes[type].hasError ? 'error' : 'inherit'}
                >
                  {tripSplitTypeNames[type as TripSplitType]}
                  {editedSplitTypes[type].isOverride && (
                    <T component="span" color={alpha('#000', 0.6)}>
                      {' '}
                      (edited)
                    </T>
                  )}
                </T>
              </MenuItem>
            ))
          : Object.values(TripSplitType)
              .filter((type) => type !== TripSplitType.DEFAULT)
              .map((type) => (
                <MenuItem value={type} key={type}>
                  <T
                    color={
                      editedSplitTypes[type].hasError ? 'error' : 'inherit'
                    }
                  >
                    {tripSplitTypeNames[type as TripSplitType]}
                    {editedSplitTypes[type].isOverride && (
                      <T component="span" color={alpha('#000', 0.6)}>
                        {' '}
                        (edited)
                      </T>
                    )}
                  </T>
                </MenuItem>
              ))}
      </Select>
      <FormHelperText error={hasAnyError}>
        {hasAnyError && getCommissionSplitsErrorsString(editedSplitTypes)}
      </FormHelperText>
    </Box>
  );
};

export default SplitTypeSelector;
