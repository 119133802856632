import { Box } from '@mui/material';
import { useState } from 'react';
import type { TripFormValues } from '../schema';
import CommissionSplitTakeDialog from './CommissionSplitTakeDialog';
import CommissionSplitsTreeItem from './CommissionSplitsTreeItem';
import type { SplitTreeItem, TripSplitType } from './dtos';

type CommissionSplitsTreeProps = {
  commissionSplits: TripFormValues['splits'];
  selectedSplitType: TripSplitType;
  onSplitChange: (split: SplitTreeItem) => void;
};

const CommissionSplitsTree = ({
  commissionSplits,
  selectedSplitType,
  onSplitChange,
}: CommissionSplitsTreeProps) => {
  if (!commissionSplits) return null;

  const [splitToEdit, setSplitToEdit] = useState<SplitTreeItem | null>(null);

  const handleSplitChange = (newTake: number) => {
    if (!splitToEdit) return;

    const updatedSplit: SplitTreeItem = {
      ...splitToEdit,
      split: { ...splitToEdit.split, takePercent: newTake },
    };

    onSplitChange(updatedSplit);
    setSplitToEdit(null);
  };

  return (
    <>
      <Box>
        {commissionSplits[selectedSplitType].splits.map(
          (splitTypeTakes, index) => {
            return (
              <CommissionSplitsTreeItem
                split={{ split: splitTypeTakes, index: index }}
                onClick={setSplitToEdit}
                key={splitTypeTakes?.id || index}
                showPrimaryAdvisorChip
              />
            );
          },
        )}
      </Box>

      {!!splitToEdit && (
        <CommissionSplitTakeDialog
          open={!!splitToEdit}
          onClose={() => setSplitToEdit(null)}
          onSave={handleSplitChange}
          commissionTake={splitToEdit.split.takePercent}
        />
      )}
    </>
  );
};

export default CommissionSplitsTree;
