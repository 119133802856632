import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import FlightIcon from '@mui/icons-material/Flight';
import LockIcon from '@mui/icons-material/Lock';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import {
  ConcreteLeadStageType,
  type LeadStageResponseDto,
  LeadStageType,
  SyntheticLeadStage,
} from 'dtos';

export enum ChipColor {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export const colorForStageType = (stageType?: LeadStageType): ChipColor => {
  switch (stageType) {
    case LeadStageType.NEW:
    case LeadStageType.TRIP_CREATED:
    case LeadStageType.PACKING:
    case LeadStageType.UNPACKING:
      return ChipColor.DEFAULT;

    case LeadStageType.BOOKED:
    case LeadStageType.TRAVELED:
      return ChipColor.SUCCESS;

    case LeadStageType.LOST:
    case LeadStageType.CANCELED:
      return ChipColor.ERROR;

    case LeadStageType.DECLINED:
      return ChipColor.WARNING;

    case LeadStageType.DISCOVERY:
    case LeadStageType.PLANNING:
    case LeadStageType.TRAVELING:
      return ChipColor.INFO;

    default:
      return ChipColor.PRIMARY;
  }
};

export const getChipData = ({
  leadStage: { name, type },
  useTripVerbiage = false,
}: {
  leadStage: LeadStageResponseDto;
  useTripVerbiage: boolean;
}): {
  label: string;
  icon?: React.ReactElement;
} => {
  switch (type) {
    case SyntheticLeadStage.PACKING:
    case SyntheticLeadStage.UNPACKING:
    case SyntheticLeadStage.TRIP_CREATED:
    case ConcreteLeadStageType.BOOKED:
    case ConcreteLeadStageType.DISCOVERY:
    case ConcreteLeadStageType.PLANNING:
    case ConcreteLeadStageType.NEW:
      return { label: name };

    case SyntheticLeadStage.LOCKED:
      return { label: name, icon: <LockIcon /> };
    case SyntheticLeadStage.CANCELED:
      return { label: name, icon: <BlockIcon fontSize="small" /> };
    case SyntheticLeadStage.TRAVELING:
      return {
        label: useTripVerbiage ? 'In Progress' : name,
        icon: <FlightIcon fontSize="small" />,
      };
    case SyntheticLeadStage.TRAVELED:
      return {
        label: useTripVerbiage ? 'Complete' : name,
        icon: <CheckCircleIcon fontSize="small" />,
      };
    case ConcreteLeadStageType.LOST:
      return {
        label: name,
        icon: <CloseIcon fontSize="small" />,
      };
    case ConcreteLeadStageType.DECLINED:
      return {
        label: name,
        icon: <ThumbDownAltIcon fontSize="small" />,
      };

    default:
      throw new Error(`Invalid stage type: ${type}`);
  }
};
