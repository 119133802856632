import { type SplitResponseDto, SupplierType } from 'dtos';
import type { FormikValues } from 'formik';

export interface CommissionSplitsFormValues extends FormikValues {
  [key: string]: SplitItem;
}

export type SplitItem = {
  pct: number;
  agencyId?: string;
  isAdvisor: boolean;
  agencyName?: string;
  color?: string;
};

enum DefaultSplit {
  DEFAULT = 'DEFAULT',
}
export const TripSplitType = {
  ...SupplierType,
  ...DefaultSplit,
} as const;
export type TripSplitType = keyof typeof TripSplitType;

export type EditedSplitTypes = {
  [key in TripSplitType]: { isOverride: boolean; hasError: boolean };
};

export type SplitTreeItem = {
  split: SplitResponseDto;
  index: number;
};
