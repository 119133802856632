import type { ClientListItemWithGroups } from '@customFormik';
import type { ListItem, SearchableListItem } from 'components/types';
import {
  type AgencyResponseDto,
  DEFAULT,
  GroupType,
  type SupplierType,
  TravelType,
  type TripResponseDto,
  type TripSplitsResponseDto,
  type TripUpdateRequestDto,
} from 'dtos';
import type { FormikValues } from 'formik';
import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';
import { TripSplitType } from './Splits/dtos';

export type ContextValues = {
  mode: 'add' | 'edit';
  tripAdvisorUserId?: string;
  userAgencies: AgencyResponseDto[];
  tripId?: string;
  commissionSplits?: TripSplitsResponseDto;
  tripNotes?: string;
  isOrgAdmin: boolean;
};

export enum FieldNames {
  AGENCY = 'agency',
  PRIMARY_TRAVELER = 'primaryTraveler',
  ADDITIONAL_TRAVELERS = 'additionalTravelers',
  NAME = 'name',
  TAGS = 'tags',
  DESTINATIONS = 'destinations',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TERMS = 'terms',
  IS_DATE_USER_SET = 'isDateUserSet',
  COMMISSION_SPLITS = 'splits',
  TRAVEL_TYPE = 'travelType',
  CORPORATE_GROUP = 'corporateGroup',
  IS_PER_SUPPLIER_TYPE_SPLIT = 'isPerSupplierTypeSplit',
}

export interface TripFormValues extends FormikValues {
  [FieldNames.AGENCY]: ListItem | null;
  [FieldNames.PRIMARY_TRAVELER]: ClientListItemWithGroups | null;
  [FieldNames.ADDITIONAL_TRAVELERS]: ListItem[];
  [FieldNames.NAME]: string;
  [FieldNames.TAGS]: string[];
  [FieldNames.DESTINATIONS]: SearchableListItem[];
  [FieldNames.START_DATE]: string | null;
  [FieldNames.END_DATE]: string | null;
  [FieldNames.TERMS]: string | null;
  [FieldNames.IS_DATE_USER_SET]: boolean;
  [FieldNames.COMMISSION_SPLITS]: TripSplitsResponseDto | null;
  [FieldNames.TRAVEL_TYPE]: TravelType;
  [FieldNames.CORPORATE_GROUP]: ListItem | null;
  [FieldNames.IS_PER_SUPPLIER_TYPE_SPLIT]: boolean;
}

const destinationsSchema = yup
  .array(
    yup.object().shape({
      id: yup.string(),
      name: yup.string(),
    }),
  )
  .label('Destinations');

const listItemsSchema = yup.object().nullable().shape({
  id: yup.string(),
  name: yup.string(),
});

const searchableListItemsSchema = yup.array(
  yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    searchableName: yup.string(),
  }),
);

const splitTypesSchema = yup
  .object()
  .shape({
    maxPercent: yup.number().min(0).max(100),
    splits: yup.array(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        color: yup.string(),
        canEdit: yup.boolean(),
        type: yup.string(),
        takePercent: yup.number().required().min(0).max(100),
      }),
    ),
  })
  .test(
    'sum-of-percentage',
    ({ value }) => `The sum of the percentages must be ${value.maxPercent}`,
    (splitType) => {
      const currentTotalTake =
        splitType.splits?.reduce((acc, treeItem) => {
          return acc + (treeItem?.takePercent ?? 0);
        }, 0) || 0;

      return currentTotalTake === splitType.maxPercent;
    },
  );

const schema = (context: ContextValues) => {
  const { mode, commissionSplits } = context;
  const commissionSplitsSchema = !context.isOrgAdmin
    ? yup.object().nullable()
    : mode === 'edit' && commissionSplits
      ? yup.object().when(FieldNames.IS_PER_SUPPLIER_TYPE_SPLIT, {
          is: true,
          then: (schema) =>
            schema.shape({
              [TripSplitType.AIRLINE]: splitTypesSchema,
              [TripSplitType.HOTEL]: splitTypesSchema,
              [TripSplitType.CRUISE_LINE]: splitTypesSchema,
              [TripSplitType.INSURANCE]: splitTypesSchema,
              [TripSplitType.OTHER]: splitTypesSchema,
              [TripSplitType.RAIL]: splitTypesSchema,
              [TripSplitType.TOUR_DMC]: splitTypesSchema,
              [TripSplitType.TRANSPORTATION]: splitTypesSchema,
              [TripSplitType.DEFAULT]: splitTypesSchema,
            }),
          otherwise: (schema) =>
            schema.shape({
              [TripSplitType.DEFAULT]: splitTypesSchema,
            }),
        })
      : yup.object().nullable();

  const yupSchema: Record<`${FieldNames}`, yup.AnySchema> = {
    agency: listItemsSchema.required().label('Agency'),
    name: yup.string().required().min(3).label('Trip Name'),
    primaryTraveler: listItemsSchema
      .label('Primary Traveler')
      .when('corporateGroup', {
        is: null,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    additionalTravelers: searchableListItemsSchema.label(
      'Additional Travelers',
    ),
    tags: yup.array(yup.string()).nullable().label('Tags'),
    destinations:
      mode === 'add'
        ? destinationsSchema
            .min(1, 'Destinations is a required field')
            .required()
        : destinationsSchema,
    startDate: yup.date().nullable().label('Start Date'),
    endDate: yup.date().nullable().label('End Date'),
    terms: yup.string().nullable().label('Terms'),
    isDateUserSet: yup.boolean().label('Is Date User Set'),
    splits: commissionSplitsSchema,
    travelType: yup.string().required().label('Travel Type'),
    corporateGroup: listItemsSchema
      .label('Company')
      .when(['travelType', 'primaryClient'], {
        is: (travelType: string, primaryClient: ListItem | null) =>
          travelType === TravelType.CORPORATE && !primaryClient,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    isPerSupplierTypeSplit: yup.boolean().label('Is Per Supplier Type Split'),
  };

  const schema = createYupSchema<TripFormValues>(yupSchema);

  return schema;
};

const getTravelType = (trip: Partial<TripResponseDto> | undefined) => {
  if (trip?.travelType) {
    return trip.travelType;
  }

  const isCorporateGroup = trip?.primaryClient?.groups?.some(
    ({ group }) => group.type === GroupType.CORPORATE,
  );

  if (isCorporateGroup || trip?.corporateGroup) {
    return TravelType.CORPORATE;
  }

  return TravelType.LEISURE;
};

const getDefaultValues = (
  trip: Partial<TripResponseDto> | undefined,
  { userAgencies }: ContextValues,
): TripFormValues => {
  const agency =
    userAgencies?.find((agency) => agency.id === trip?.agencyId) ||
    userAgencies[0] ||
    null;

  const travelType = getTravelType(trip);

  const primaryClientCorporateGroups =
    trip?.primaryClient?.groups?.filter(
      ({ group }) => group.type === GroupType.CORPORATE,
    ) || null;

  const corporateGroup = trip?.corporateGroup
    ? { id: trip.corporateGroup.id, name: trip.corporateGroup.name }
    : primaryClientCorporateGroups?.length === 1
      ? {
          id: primaryClientCorporateGroups[0].group.id,
          name: primaryClientCorporateGroups[0].group.name,
        }
      : null;

  const isPerSupplierTypeSplit = hasCustomSplits(
    trip?.splits as TripSplitsResponseDto,
  );

  return {
    primaryTraveler: trip?.primaryClient
      ? {
          id: trip.primaryClient.id,
          name: trip.primaryClient.name,
          type: 'client',
          groups: trip.primaryClient.groups,
        }
      : null,
    agency,
    additionalTravelers:
      trip?.additionalClients?.map((c) => ({
        id: c.id,
        name: c.name,
      })) || [],
    name: trip?.name || '',
    tags: trip?.tags || [],
    destinations:
      trip?.destinations?.map((d) => ({
        id: d.id,
        name: d.name,
        searchableName: d.name,
      })) || [],
    startDate: trip?.startDate || null,
    endDate: trip?.endDate || null,
    isDateUserSet: trip?.isDateUserSet || false,
    terms: trip?.terms || '',
    splits: (trip?.splits as TripSplitsResponseDto) || null,
    travelType,
    corporateGroup,
    isPerSupplierTypeSplit,
  };
};

const getEntityFromFormValues = (
  values: TripFormValues,
  context?: ContextValues,
): TripUpdateRequestDto => {
  const splits = { ...values.splits } as TripSplitsResponseDto;

  if (!values.isPerSupplierTypeSplit) {
    const splitsKeys = Object.keys(splits);
    for (const key of splitsKeys) {
      const typedKey = key as 'DEFAULT' | SupplierType;
      splits[typedKey] = { ...splits[DEFAULT] };
    }
  }

  return {
    agencyId: values.agency?.id || '',
    name: values.name,
    primaryClientId: (values.primaryTraveler as ListItem)?.id || undefined,
    additionalClientIds: values?.additionalTravelers?.map(
      (t: ListItem) => t.id,
    ),
    startDate: values.startDate || undefined,
    endDate: values.endDate || undefined,
    isDateUserSet: values.isDateUserSet,
    tags: values.tags,
    destinationIds: values.destinations.map((d: SearchableListItem) => d.id),
    terms: values.terms || '',
    notes: context?.tripNotes ?? '',
    splits: splits || undefined,
    travelType: values.travelType,
    corporateGroupId:
      values.travelType === TravelType.CORPORATE
        ? values.corporateGroup?.id
        : undefined,
  };
};

const formSchema: ExtendedFormSchema<
  Partial<TripResponseDto> | undefined,
  TripUpdateRequestDto,
  TripFormValues,
  typeof schema,
  ContextValues
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default formSchema;

const hasCustomSplits = (tripSplits?: TripSplitsResponseDto) =>
  hasOverrides(tripSplits) || splitsAreDifferentFromDefault(tripSplits);

const hasOverrides = (tripSplits?: TripSplitsResponseDto) => {
  return !tripSplits
    ? true
    : Object.entries(tripSplits).some(
        ([key, splitType]) =>
          key !== DEFAULT && splitType.splits.some((s) => s.isOverride),
      );
};

const splitsAreDifferentFromDefault = (tripSplits?: TripSplitsResponseDto) => {
  return !tripSplits
    ? true
    : Object.values(tripSplits).some((splitType) =>
        splitType.splits.some(
          (s, index) =>
            s.takePercent !== tripSplits.DEFAULT.splits[index].takePercent,
        ),
      );
};
