import {
  AgencySelector,
  type ClientListItemWithGroups,
  ClientMultiselect,
  ClientSelector,
  CorporateGroupSelector,
  DatePicker,
  DestinationMultiselect,
  RadioGroup,
  TagMultiselect,
  TextField,
} from '@customFormik';
import { Stack } from '@mui/material';
import type { ListItem } from 'components/types';
import { type ClientResponseDto, GroupType, TagType, TravelType } from 'dtos';
import { useFormikContext } from 'formik';
import useUserInfo from 'hooks/useUserInfo';
import { useState } from 'react';
import CommissionSplits from './Splits/CommissionSplits';
import { type ContextValues, FieldNames, type TripFormValues } from './schema';

type TripFormProps = {
  context: ContextValues;
};

const TripForm = ({ context }: TripFormProps) => {
  const { mode, tripAdvisorUserId, userAgencies, tripId, commissionSplits } =
    context;
  const { userInfo } = useUserInfo();

  const {
    values: {
      primaryTraveler,
      additionalTravelers,
      travelType,
      startDate,
      corporateGroup,
    },
    setFieldValue,
  } = useFormikContext<TripFormValues>();

  const [showTravelType, setShowTravelType] = useState(
    primaryTraveler?.groups?.some(
      ({ group }) => group.type === GroupType.CORPORATE,
    ) ?? !!corporateGroup,
  );

  const handleAddNewClientSuccess = (
    client: ClientResponseDto,
    isAdditionalTraveler = false,
  ) => {
    if (isAdditionalTraveler) {
      setFieldValue(
        FieldNames.ADDITIONAL_TRAVELERS,
        additionalTravelers.concat({
          id: client.id,
          name: client.name,
        }),
      );
    } else {
      setFieldValue(FieldNames.PRIMARY_TRAVELER, {
        id: client.id,
        name: client.name,
      });
    }
  };

  const handlePrimaryTravelerChange = (
    client: ClientListItemWithGroups | undefined,
    tripCorporateGroup: ListItem | null,
  ) => {
    if (!client) {
      setFieldValue(FieldNames.PRIMARY_TRAVELER, null);
      return;
    }

    setFieldValue(FieldNames.PRIMARY_TRAVELER, client);

    const clientCorporateGroups = client.groups?.filter(
      ({ group }) => group.type === GroupType.CORPORATE,
    );
    const [clientCorporateGroup] = clientCorporateGroups;

    const corporateGroup: ListItem | null = tripCorporateGroup
      ? {
          id: tripCorporateGroup.id,
          name: tripCorporateGroup.name,
        }
      : clientCorporateGroup
        ? {
            id: clientCorporateGroup.group.id,
            name: clientCorporateGroup.group.name,
          }
        : null;

    const hasCorporateGroup = !!corporateGroup;

    if (hasCorporateGroup) {
      setShowTravelType(true);
      setFieldValue(FieldNames.TRAVEL_TYPE, TravelType.CORPORATE);
      setFieldValue(FieldNames.CORPORATE_GROUP, corporateGroup);
    } else {
      setFieldValue(FieldNames.TRAVEL_TYPE, TravelType.LEISURE);
      setShowTravelType(false);
    }
  };

  const showCommissionSplits = Boolean(
    userInfo?.isOrgUser &&
      !userInfo.isOrgAssistant &&
      mode === 'edit' &&
      tripId,
  );

  return (
    <Stack spacing={2}>
      {userAgencies.length > 1 && (
        <AgencySelector
          label="Agency"
          name={FieldNames.AGENCY}
          helperText="Select an agency for this trip"
          tripAdvisorUserId={tripAdvisorUserId}
          required
        />
      )}

      <ClientSelector
        label="Primary Traveler"
        name={FieldNames.PRIMARY_TRAVELER}
        fieldId={FieldNames.PRIMARY_TRAVELER}
        required={!corporateGroup}
        allowAdd
        onAddSuccess={(client) => handleAddNewClientSuccess(client)}
        helperText="Select contact as primary traveler"
        showGroups
        onChange={(client) => {
          handlePrimaryTravelerChange(
            client as ClientListItemWithGroups,
            corporateGroup,
          );
        }}
        displayEmailAndPhone={true}
      />
      <ClientMultiselect
        label="Additional Travelers"
        name={FieldNames.ADDITIONAL_TRAVELERS}
        fieldId={FieldNames.ADDITIONAL_TRAVELERS}
        allowAdd
        onAddSuccess={(client) => handleAddNewClientSuccess(client, true)}
        helperText="Select any additional travelers"
        showGroups
        displayEmailAndPhone={true}
        clientIdForGroupsSection={primaryTraveler?.id}
      />
      <DestinationMultiselect
        label="Destinations"
        name={FieldNames.DESTINATIONS}
        required={mode === 'add'}
      />
      <TextField name={FieldNames.NAME} label="Trip Name" required fullWidth />

      <Stack direction="row" spacing={2}>
        <DatePicker name={FieldNames.START_DATE} label="Start Date" />
        <DatePicker
          name={FieldNames.END_DATE}
          label="End Date"
          {...(startDate && { referenceDate: startDate })}
        />
      </Stack>

      <TagMultiselect
        name={FieldNames.TAGS}
        label="Tags"
        type={TagType.TRIP}
        allowAdd={true}
      />

      {showTravelType && (
        <>
          <RadioGroup
            name={FieldNames.TRAVEL_TYPE}
            label="Travel Type"
            options={[
              { label: 'Leisure', value: TravelType.LEISURE },
              { label: 'Corporate', value: TravelType.CORPORATE },
            ]}
          />

          {travelType === TravelType.CORPORATE && (
            <CorporateGroupSelector
              name={FieldNames.CORPORATE_GROUP}
              label={'Company'}
              required
              clientId={primaryTraveler?.id}
            />
          )}
        </>
      )}

      {showCommissionSplits && commissionSplits && (
        <CommissionSplits
          name={FieldNames.COMMISSION_SPLITS}
          originalCommissionSplits={commissionSplits}
        />
      )}

      {/* {mode === 'add' && (
        <FormControlLabel
          control={
            <Checkbox
              name={FieldNames.CREATE_CLIENT_INVOICE}
              onChange={(_event, checked) => setCreateClientInvoice(checked)}
            />
          }
          label='Create client payment'
        />
      )} */}
    </Stack>
  );
};

export default TripForm;
