import { ExtendedFormDialog } from '@customFormik';
import { useUserInfoContext } from 'context/user-info.context';
import type {
  TripResponseDto,
  TripSplitsResponseDto,
  TripUpdateRequestDto,
} from 'dtos';
import { useTrip, useUserAgencies } from 'hooks';
import { createTrip, updateTrip } from 'requests/trips';
import TripForm from './TripForm';
import formSchema from './schema';

type TripDialogProps = {
  mode: 'add' | 'edit';
  open: boolean;
  setOpen: (isOpen: boolean) => Promise<void> | void;
  tripId?: string;
  trip?: Partial<TripResponseDto>;
  onSuccess?: (
    trip: TripResponseDto,
    createClientInvoice: boolean,
  ) => Promise<void> | void;
};

export default function TripDialog({
  open,
  setOpen,
  mode,
  tripId,
  trip: tripTemplate,
  onSuccess = () => Promise.resolve(),
}: TripDialogProps) {
  const { trip } = useTrip(tripId);

  const userInfo = useUserInfoContext();

  const { agencies: userAgencies } = useUserAgencies({
    getAll: true,
    sortModel: { sort: 'lastUsed', sortDirection: 'desc' },
    userId: trip?.advisorUserId,
  });

  const onSave = (values: TripUpdateRequestDto) => {
    const tripFormValues = { ...values };

    if (
      mode === 'edit' &&
      trip &&
      hasUserChangedDateInput(tripFormValues, trip)
    )
      tripFormValues.isDateUserSet = true;

    const promise =
      mode === 'add'
        ? createTrip(tripFormValues)
        : updateTrip(tripFormValues, tripId || trip?.id || '');

    return promise;
  };

  const handleSuccess = async (entity: TripResponseDto) => {
    await setOpen(false);
    await onSuccess(entity, false);
  };

  if (mode === 'edit' && !trip) return null;

  return (
    <ExtendedFormDialog
      title={mode === 'add' ? 'New Trip' : trip?.name || ''}
      open={open}
      setOpen={() => setOpen(false)}
      FormComponent={TripForm}
      context={{
        mode,
        tripAdvisorUserId: trip?.advisorUserId,
        userAgencies: userAgencies || [],
        tripId: tripId || trip?.id,
        commissionSplits: trip?.splits as TripSplitsResponseDto,
        tripNotes: trip?.notes,
        isOrgAdmin: userInfo?.isOrgAdmin ?? false,
      }}
      entity={trip ?? tripTemplate}
      onSave={onSave}
      onSuccess={handleSuccess}
      formSchema={formSchema}
      saveButtonText={`${mode === 'add' ? 'Create' : 'Update'} Trip`}
      width={600}
    />
  );
}

const hasUserChangedDateInput = (
  values: TripUpdateRequestDto,
  trip: TripResponseDto,
): boolean => {
  return (
    (values.startDate !== trip?.startDate ||
      values.endDate !== trip?.endDate) &&
    !trip?.isDateUserSet
  );
};
