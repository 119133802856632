import { FormControl, FormControlLabel, Switch } from '@mui/material';
import SplitTypeSelector from 'components/common/SplitTypeSelector';
import type { TripSplitsResponseDto } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { type ChangeEvent, useEffect, useState } from 'react';
import { FieldNames, type TripFormValues } from '../schema';
import ErrorSection from './CommissionSplitsErrorSection';
import Header from './CommissionSplitsHeader';
import LinearProgressSection from './CommissionSplitsLinearProgress';
import CommissionSplitsTree from './CommissionSplitsTree';
import {
  type EditedSplitTypes,
  type SplitTreeItem,
  TripSplitType,
} from './dtos';
import { calculateEditedSplitTypes, calculateTotalTakePct } from './utils';

type CommissionSplitsProps = {
  name: string;
  originalCommissionSplits: TripSplitsResponseDto;
};

const CommissionSplits = ({
  name,
  originalCommissionSplits,
}: CommissionSplitsProps) => {
  const [{ value: fields }, meta, { setValue }] =
    useField<TripFormValues['splits']>(name);
  const {
    submitCount,
    values: { isPerSupplierTypeSplit },
    setFieldValue,
  } = useFormikContext<TripFormValues>();
  const [currentTotalTakePct, setCurrentTotalTakePct] = useState<number>(0);
  const [editedSplitTypes, setEditedSplitTypes] = useState<EditedSplitTypes>(
    calculateEditedSplitTypes({ formSplits: fields }),
  );

  const [selectedSplitType, setSelectedSplitType] = useState<TripSplitType>(
    isPerSupplierTypeSplit ? TripSplitType.AIRLINE : TripSplitType.DEFAULT,
  );

  useEffect(() => {
    if (!fields) return;

    setCurrentTotalTakePct(
      calculateTotalTakePct({ selectedSplitType, splits: fields }),
    );
    setEditedSplitTypes(calculateEditedSplitTypes({ formSplits: fields }));
  }, [fields]);

  useEffect(() => {
    setCurrentTotalTakePct(
      calculateTotalTakePct({ selectedSplitType, splits: fields }),
    );
  }, [selectedSplitType]);

  const onSplitChange = (updatedSplit: SplitTreeItem) => {
    if (!fields) return;

    const updatedSplits: TripFormValues['splits'] = {
      ...fields,
      [selectedSplitType]: {
        ...fields[selectedSplitType],
        splits: fields[selectedSplitType].splits.map((split, index) => {
          const updateById = Boolean(updatedSplit.split.id && split.id);

          return updateById
            ? split.id === updatedSplit.split.id
              ? updatedSplit.split
              : split
            : index === updatedSplit.index
              ? updatedSplit.split
              : split;
        }),
      },
    };

    setValue(updatedSplits);
  };

  const setSupplierTypesSplitsToDefault = () => {
    const updatedSplits = { ...fields };

    Object.keys(TripSplitType).forEach((splitType) => {
      updatedSplits[splitType as TripSplitType] =
        originalCommissionSplits['DEFAULT'];
    });

    setValue(updatedSplits as TripFormValues['splits']);
  };

  const onSwitchClick = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (!checked) {
      setSelectedSplitType(TripSplitType.DEFAULT);
    } else {
      setSelectedSplitType(TripSplitType.AIRLINE);
    }
    setFieldValue(FieldNames.IS_PER_SUPPLIER_TYPE_SPLIT, checked);
  };

  const error = submitCount > 0 && !!meta.error;

  return (
    <FormControl error={error}>
      <Header onResetValues={setSupplierTypesSplitsToDefault} />

      <ErrorSection
        currentTotalTakePct={currentTotalTakePct}
        maxPercent={originalCommissionSplits[selectedSplitType].maxPercent}
      />

      <LinearProgressSection
        currentTotalTakePct={currentTotalTakePct}
        maxPercent={
          (fields as TripSplitsResponseDto)[selectedSplitType].maxPercent
        }
      />

      <FormControlLabel
        control={
          <Switch checked={isPerSupplierTypeSplit} onChange={onSwitchClick} />
        }
        label="Per-Supplier Type Splits"
        sx={{ my: 2 }}
      />

      <SplitTypeSelector
        selectedSplitType={selectedSplitType}
        editedSplitTypes={editedSplitTypes}
        setSelectedSplitType={setSelectedSplitType}
        sx={{ mb: 1 }}
        isPerSupplierTypeSplit={isPerSupplierTypeSplit}
      />

      <CommissionSplitsTree
        commissionSplits={fields}
        selectedSplitType={selectedSplitType}
        onSplitChange={onSplitChange}
      />
    </FormControl>
  );
};

export default CommissionSplits;
